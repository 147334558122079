export const jsonToFormData = (payload) => {
  const formData = new FormData();
  
  for (let k of Object.keys(payload ?? {})) {
    const val = payload[k];
    if (val === null || val === undefined) {
      // Skip null and undefined values or append an empty string
      formData.append(k, '');
    }else if (Array.isArray(val)&&k==="language") {
      // Handle arrays
      if (val.length === 0) {
        formData.append(k, '[]'); // Append empty array as '[]'
      } else {
        for (let v of val) {
          formData.append(`${k}`, v); // Append array items with key[]
        }
      }
    } 
     else if (Array.isArray(val)) {
      // Handle arrays
      if (val.length === 0) {
        formData.append(k, '[]'); // Append empty array as '[]'
      } else {
        for (let v of val) {
          formData.append(`${k}[]`, v); // Append array items with key[]
        }
      }
    } 
    else if (typeof val === 'object' && !(val instanceof File)) {
      // Handle nested objects (except File instances)
      if (Object.keys(val).length === 0) {
        formData.append(k, '{}'); // Append empty object as '{}'
      } else {
        formData.append(k, JSON.stringify(val)); // Append as JSON string
      }
    } else {
      // Handle regular values and File instances
      formData.append(k, val);
    }
  }
  
  return formData;
};


export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}

export const parseLocation = (location) => {
  if (typeof location === 'string') {
    try {
      return JSON.stringify(location);
    } catch (e) {
      console.error(e);
    }
  } else {
    return location;
  }
};

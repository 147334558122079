export const APIEndPoints = {
  Certificate: 'api/v1/user/profile/certificate',
  Milestone: 'api/v1/user/profile/milestone',
  UserProfileInfo: 'api/v1/user/profile/info',
  ProfileExperience: 'api/v1/user/profile/experience',
  InactiveUserChangeEmail: 'api/v1/user/change-email',
  ProfileActivity: ({ pageSize = 5, page = 1 }) =>
    `api/v1/user/activity?page=${page}&page_size=${pageSize}`,
  PublicProfileActivity: ({ pageSize = 5, page = 1, userId }) =>
    `api/v1/user/activity/by_user/${userId}?page=${page}&page_size=${pageSize}`,
  ProfileSkills: 'api/v1/user/profile/skills/',
  UserAnalytics: 'api/v1/user/analytics',
  ResetPassword: 'api/v1/password_resetconfirm',
  Forgotpassword: 'api/v1/password_reset',
  GoogleSign: 'api/v1/google/register',
  SignIn: 'api/token',
  Register: 'api/v1/register',
  Education: 'api/v1/user/profile/education',
  Universities: 'api/v1/universities',
  School: 'api/v1/schools',
  Course: 'api/v1/courses',
  Degree: 'api/v1/degrees',
  Industries: 'api/v1/gd/industries',
  Companies: 'api/v1/companies',
  Certificates: 'api/v1/certificates',
  CertAuthorities: 'api/v1/cert_authorities',
  locations: 'api/v1/locations',
  Skills: 'api/v1/gd/skills',
  Connections: 'api/v1/user/connection',
  MyDisk_Root: 'api/v1/disk/dir/',
  MyDisk: 'api/v1/disk/dir/',
  MyDiskRoot: 'api/v1/disk/',
  TeamDisk: 'api/v1/disk/team/dir/',
  MyDisk_Folder: 'api/v1/disk/dir/',
  MyDisk_MuiltiFolder: 'api/v1/disk/multidir/',
  MyDisk_MuiltiFiles: 'api/v1/disk/multifiles/',
  MyDisk_Files: 'api/v1/disk/files/',
  MyDisk_Search: 'api/v1/disk/search/',
  SharedDisk_Search: 'api/v1/shared-disk/search',
  shareDisk: 'api/v1/shared-disk/',
  shareDiskUser: 'api/v1/shared-disk/sharee/',
  AllConnections: 'api/v1/user/profile/connection/',
  BlockConnection: 'api/v1/user/connection/block',
  BlockReportPost: 'api/v1/report/post',
  UnblockConnection: 'api/v1/user/connection/unblock',
  ReportConnection: 'api/v1/user/connection/report',
  RequestConnection: 'api/v1/user/connection',
  RemindConnectionRequest: (id) => `api/v1/user/remind/connection/${id}/`,
  UnfriendConnection: (id) => `api/v1/user/unfriend/${id}`,
  MutualConnections: ({ userId, query, size = 10, pageParam = 1 }) =>
    `api/v1/user/${userId}/connection/limited_mutual/?&page_size=${size}&page=${pageParam}&query=${
      query ? query : ''
    }`,
  DegreeConnectionsSelfUser: ({
    degree = 1,
    query,
    size = 10,
    pageParam = 1,
  }) =>
    `api/v1/user/connections/?degree_num=${degree}&page_size=${size}&page=${pageParam}&query=${
      query ? query : ''
    }`,
  DegreeConnectionsByUserId: ({
    userId,
    degree = 1,
    query,
    size = 10,
    pageParam = 1,
  }) =>
    `api/v1/user/degree_wise/connections/${userId}/?degree_num=${degree}&page_size=${size}&page=${pageParam}&query=${
      query ? query : ''
    }`,
  DegreeConnectionsByUserIdLimited: ({ userId, degree = 1, limit }) =>
    `api/v1/user/degree_wise/connections/${userId}/?degree_num=${degree}&page_size=${limit}&page=1`,
  ConnectionsByUserId: (userId) => `api/v1/user/connection/${userId}`,
  Invitations: ({ type, size = 10, pageParam = 1 }) =>
    `api/v1/user/connection/manage-invite/?list_type=${type}&page_size=${size}&page=${pageParam}`,
  FollowingPages: ({ query, size = 10, pageParam = 1 }) =>
    `api/v1/user/company/page/following-list/?page_size=${size}&page=${pageParam}&query=${
      query ? query : ''
    }`,
  Suggestions: (type) =>
    `api/v1/user/connection/manage-invite/suggestion?filter_type=${type}`,
  SuggestionsPublic: (body) =>
    `api/v1/user/connection/manage-invite/suggestion?filter_type=${body.type}&with_user=${body.userId}`,
  // Post: 'api/v1/posts',
  getPost: (id) => 'api/v1/post/' + id + '/detail',
  Post: (page = 1, size = 10, location) =>
    `api/v1/posts?page_size=${size}&page=${page}&${new URLSearchParams({
      country: location?.country ?? '',
      state: location?.state ?? '',
      city: location?.city ?? '',
    }).toString()}`,
  Reels: (page = 1, size = 10, only_reels = true) =>
    'api/v1/posts?page_size=' +
    size +
    '&page=' +
    page +
    '&only_reels=' +
    only_reels,
  MyActivity: (page = 1, size = 10) =>
    'api/v1/user/activity?page_size=' + size + '&page=' + page,
  CompanyPost: (page = 1, size = 10, companyId) =>
    'api/v1/posts?page_size=' +
    size +
    '&page=' +
    page +
    '&company_id=' +
    companyId,
  CompanyPostPublic: (page, size, companyId) =>
    'api/v1/posts?page_size=' +
    size +
    '&page=' +
    page +
    '&company_id=' +
    companyId,
  Events: 'api/v1/event/',
  NewPost: 'api/v1/post/create',
  AllEvents: 'api/v1/event/list/upcoming?page=1&page_size=100',
  EventDetails: (postId) => 'api/v1/event/' + postId + '/detail',
  AcceptEvent: (postId) => 'api/v1/event/' + postId + '/accept',
  RegisterEvent: (postId) => 'api/v1/event/' + postId + '/register',
  IgnoreEvent: (postId) => 'api/v1/event/' + postId + '/ignore',
  GetEvents: (page = 1, size = 20) =>
    `api/v1/event/list?page_size=${size}&page=${page}`,
  GetUpcomingEvents: `api/v1/event/list/upcoming`,
  GetMyEvents: `api/v1/event/list`,
  GetPastEvents: `api/v1/event/list/past`,
  AllMeetings: 'api/v1/meetingwithdraft',
  UpcomingEvents: (date) => 'api/v1/meeting/upcoming?limit=3&date=' + date,
  SharedConnections: (id) => 'api/v1/user/' + id + '/connection/mutual',
  PostDislike: (id) => `api/v1/post/${id}/dislike`,
  PostLike: (id) => 'api/v1/post/' + id + '/like',
  PostRepost: (id) => 'api/v1/repost/' + id,
  PostUntag: 'api/v1/untag/post',
  TodayEvents: (date) =>
    'api/v1/meeting?year=' +
    date.year +
    '&month=' +
    date.month +
    '&day=' +
    date.date,
  Accounts: 'api/v1/user/settings/update',
  Email: 'api/v1/user/emails',
  PrimaryEmail: 'api/v1/user/emails/primary',
  Deactivate: 'api/v1/user/deactivate',
  Activate: 'api/v1/activate',
  MatrixChatToken: 'api/v1/get_matrix_token/',
  MatrixEncryptDetails: 'api/v1/get_matrix_creds/',
  VerifyEmail: 'api/v1/user/emails/verify',
  changePassword: 'api/v1/user/change-password',
  mobileVerifyCode: 'api/v1/user/mobile/verify',
  Teams: 'api/v1/user/team/',
  Support: 'api/v1/support/ticket',
  Team: 'api/v1/team/',
  Companies_Page: 'api/v1/user/company/page/',
  Pages: 'api/v1/user/company/page/',
  Slug: 'api/v1/slug/',
  Testimonial: 'api/v1/testimonial/',
  Follow: ({ type, query, size = 10, pageParam = 1 }) =>
    `api/v1/user/follow/?follow_type=${type}&page=${pageParam}&page_size=${size}&query=${
      query ? query : ''
    }`,
  Follower: 'api/v1/user/follower',
  PaymentCards: 'api/v1/user/payment/card/',
  DiskCheckout: 'api/v1/disk/subscription/stripe-checkout-page/',
  BillingHistory: `api/v1/billing/history`,
  newMeeting: 'api/v1/meeting',
  proposeNewTime: (meetingId) => `api/v1/meeting/${meetingId}/propose`,
  acceptProposeNewTime: ({ meetingId, attendeeId }) =>
    `api/v1/meeting/${meetingId}/proposal/${attendeeId}/accept`,
  monthlyCalendarEvents: (month, year) =>
    'api/v1/meeting?month=' + month + '&year=' + year,
  updateAttendeeStatus: (meetingId, id) =>
    'api/v1/meeting/' + meetingId + '/attendee/' + id + '/update',
  fetchingAllCalendarEvents: (url) => 'api/v1/meeting?page=' + url,
  PublicProfile: 'api/v1/user/profile/public/',
  PostDelete: (id) => 'api/v1/post/' + id,
  PinPost: (id) => 'api/v1/post/' + id + '/pin',
  UpdatePost: (id) => 'api/v1/post/' + id + '/edit',
  IgnorePost: (id) => 'api/v1/post/' + id + '/ignore',
  GetStatus: (email) => 'api/v1/user/onboarding/?email=' + email,
  SetOnboardingData: (currStep, email) =>
    'api/v1/user/onboarding/?step=' + currStep + '&email=' + email + '',
  getConnections: 'api/v1/user/connection',
  Notification: 'api/v1/notifications',
  Resend: (email) => 'api/v1/user/onboarding/?step=resend_mail&email=' + email,
  // Search: (query) => `api/v1/search/?query=${query}`,
  Search: ({ query, category = '', pageParam = 1, params, pageSize = 10 }) =>
    `api/v1/search/${
      category?.length ? `${category}/` : ''
    }${`?page=${pageParam}`}${query?.length ? `&query=${query}` : ''}${
      params?.length ? `&${params}` : ''
    }&page_size=${pageSize}`,
  SearchPublic: ({
    query,
    category = '',
    pageParam = 1,
    params,
    pageSize = 10,
  }) =>
    `api/v1/search/public/${
      category?.length ? `${category}/` : ''
    }${`?page=${pageParam}`}${query?.length ? `&query=${query}` : ''}${
      params?.length ? `&${params}` : ''
    }&page_size=${pageSize}`,
  SearchAllPublic: ({ query, pageParam = 1, params, pageSize = 10 }) =>
    `api/v1/public/search/${`?page=${pageParam}`}${
      query?.length ? `&query=${query}` : ''
    }${params?.length ? `&${params}` : ''}&page_size=${pageSize}`,
  SearchItem: (searchType) =>
    `api/v1/search/${searchType.item}/?query=${searchType.searchQuery ?? ''}${
      searchType.query ? `${searchType.query}` : ''
    }&page_size=${searchType?.pageSize ?? 10}`,
  ArticleSearch: ({
    member_id,
    page = 1,
    page_size = 10,
    add_to_profile = '',
  }) =>
    `api/v1/search/articles/?from_member=${member_id}&add_to_profile=${add_to_profile}&page=${page}&page_size=${page_size}`,
  RecentSearch: 'api/v1/search/history',
  StaticPages: 'api/v1/static-pages',
  CreateBlog: 'api/v1/post/create',
  // Notification: 'api/v1/notifications',
  TeamCommunity: `api/v1/team/`,
  ReportTeamCommunity: `api/v1/report/team`,
  SendTeamMessage: `api/v1/send_matrix_event/`,
  TeamCommunityDisk: 'api/v1/team/disk/subscription/',
  PremiumSubscription: 'api/v1/premium/subscription',
  DownloadDiskFile: ({ fileId = '', parentId = '' }) =>
    `api/v1/disk/files/${fileId}/?act=download&parent=${parentId}`,
  TeamChat: 'api/v1/team_chat/posts/',
  listBlog: 'api/v1/blog/list?status=1',
  publishBlog: (query) => `api/v1/blog/${query}/publish`,
  editBlog: (query) => `api/v1/post/${query}/edit`,
  listPublished: 'api/v1/blog/list?status=2',
  diskSubscription: 'api/v1/disk/subscription',
  diskSize: 'api/v1/disk/size/',
  //? Integration urls
  GOOGLE_INTEGRATION: 'api/v1/integration/google/',
  
  GOOGLE_INTEGRATION_CONTACT: 'api/v1/user/onboarding/?step=contact&email="',
  GOOGLE_INTEGRATION_CALLBACK: (querySting) =>
    `api/v1/integration/google/callback/?code=${querySting?.code}&state=${querySting?.state}`,
  // `&authuser=${querySting?.authuser}&prompt=${querySting?.prompt}&scope=${querySting?.scope}&hd=${querySting?.hd}`,
  GOOGLE_EVENT: 'api/v1/event/google/',
  ZOOM_INTEGRATION: 'api/v1/integration/zoom/',
  // '?code=bJ5jl9hcKccprl2vPJRSXaGBJ-8pozCRA&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5NDE1MDk4LCJpYXQiOjE3MjY4MjMwOTgsImp0aSI6IjA2MjVmNTUwMzE0ZTRiZjA4NTRjNTdlOWEwOWZhY2Q0IiwidXNlcl9pZCI6MTAzMn0.fbMSxh3uNYDyj1Esoub9ulgepOMJ-oTaAIaNiUhRU4I',
  ZOOM_INTEGRATION_CALLBACK: (querySting) =>
    `api/v1/integration/zoom/callback/?code=${querySting?.code}&state=${querySting?.state}`,
  WEBEX_INTEGRATION: 'api/v1/integration/webex/',
  // '?code=bJ5jl9hcKccprl2vPJRSXaGBJ-8pozCRA&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5NDE1MDk4LCJpYXQiOjE3MjY4MjMwOTgsImp0aSI6IjA2MjVmNTUwMzE0ZTRiZjA4NTRjNTdlOWEwOWZhY2Q0IiwidXNlcl9pZCI6MTAzMn0.fbMSxh3uNYDyj1Esoub9ulgepOMJ-oTaAIaNiUhRU4I',
  WEBEX_INTEGRATION_CALLBACK: (querySting) =>
    `api/v1/integration/webex/callback/?code=${querySting?.code}&state=${querySting?.state}`,
  GOTO_INTEGRATION: 'api/v1/integration/gotomymeeting/',
  GOTO_INTEGRATION_CALLBACK: (querySting) =>
    `api/v1/integration/gotomymeeting/callback/?code=${querySting?.code}&state=${querySting?.state}`,
  MS_TEAMS_INTEGRATION: 'api/v1/integration/microsoft/team/',
  MS_TEAMS_INTEGRATION_CALLBACK: (querySting) =>
    `api/v1/integration/microsoft/team/callback/?code=${querySting?.code}&state=${querySting?.state}`,
    // `api/v1/integration/zoom/callback/?code=xR08rlQEJqpTUWq3ojuTTmYg6otYnp13Q&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5NzU1NTU2LCJpYXQiOjE3MjcxNjM1NTYsImp0aSI6IjQ0MjdiNWI4NmRkMDQzYzdiMjk1NjU5MWNhOGViN2Q3IiwidXNlcl9pZCI6MTAzMn0.PytVw25GDN6DxbkqHpqF35OjPzva7JpYhI8ezsBugoE`,
// 'https://v4.bybk.org/settings/integration?state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5NzQxMjc0LCJpYXQiOjE3MjcxNDkyNzQsImp0aSI6ImI3MTMwNzVjZWZiNTQ2MzI4ZTBjNGU0OTJjMTEzZjdjIiwidXNlcl9pZCI6ODQyfQ.nCTDF8VlKkoWrBnTHYeWFrAL6lEHqduxHO10Tclzep0&code=4%2F0AQlEd8yMc8Xw08Nbwkld1Nd4GBd5f2sB7wJ0v57yoRdxY-fbmEK1iBfFBDr_yDSUBwkldw&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcontacts.readonly'
// https://v4.bybk.org/settings/integration/teams?code=0.AW8BA-ZYJjydy02w8kC6n3IJijr0XYGpSPdOjdmwFz8cKz9vAQA.AgABBAIAAADW6jl31mB3T7ugrWTT8pFeAwDs_wUA9P-3B-tOddOTUUiz0VhjAhRx4N33F1QP5qtuUywN_q7OrfM9x74eLZi-6vPHaC4Z0ivdL-rQtxtIas5Axlcq7g0ZVlen7JYUrBxr3GjQbCsNz-7-84pUUMHs7RGiW1BNIRUwRQNy5YY2ob8XotpEtUTWpl1KDc-vxlQYoY-_fEUSYzWln-7RQ9JVC5uMO9mbOwG-rvXG5GdFgO_LS5iZ7iChA4Xvi4UR0MuJpeaeU7-V7AErExd-Njc4Io1tG_GI_HPuaDvPX7vfNSZpduk-cHhRgmSNlb2A7ygdn5J_2ExTl1oI4vlezBvT7Bl86jXy-I1ToDqv4NoRdxArI34T2QiS0KrZomHm6RRaKXg8CH2Id2Ru7Ite9JsPeeDyKWR7JUeP6Maj5TQ6KOQuH7I-pmOA05477JBH0J8DSKWYTCgps-Qkvo0gxRbVnLKSVPEZiviDXso-kT35GQ0mXnlT3G2Ek67tKVvEAU8sCF3XzfSvxx1lIrXI92zp1eG3BGx4nxqcl6YFNE7S2J7ZMcfltx1CBz3j8pvPOgcpn9gFxCI-B-QQw6whH66MQkOxaUYPfB_weaDMCkaITACABLnz73UYqnezDho7zxjWj5vAS3f-rMbtqMA0Fj75vAjDD99AgKxXNCF6oL5S290g0mEm0CjhmrHQDy_Zn0UzJFGcowy42C8bNc2g9tX5Oo9PDFEOJnDFXZl7JBvualWvFezHLWygfu-ozPlExTLc9AtyK2V6KwnpCuOLrvfEyVgqr0YlQ7AGxhEus5rdA3I3Z2cwf9DcJYr-FN7KZCE0PRJME6QC2tYcX78mK8PDgpY&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5ODU0NjE4LCJpYXQiOjE3MjcyNjI2MTgsImp0aSI6ImNlMTUyYTg1Mzk2MTQ5NGY5YTM0YzNlOTJjNTE0M2RmIiwidXNlcl9pZCI6MTAzMn0.olNM82Wx3uGlk5dAD4DVJ-pFq6ewDvs2ZUHL5dSiiow&session_state=da3306b5-d8e3-4af7-9848-1dceeca59c01#
  OUTLOOK_INTEGRATION_CONTACT:
    'https://login.microsoftonline.com/common/oauth2/v2.0/token',
  OUTLOOK_INTEGRATION_CALLBACK: (querySting) =>
    `api/v1/integration/microsoft/callback/?code=${querySting?.code}&state=${querySting?.state}`,
  OUTLOOK_INTEGRATION: 'api/v1/integration/microsoft/',
  OUTLOOK_EVENT: 'api/v1/event/microsoft/',
  GetNews: (pageNo, limitPerPage) =>
    `api/v1/news/list?page=${pageNo}&page_size=${limitPerPage}`,
  GetBlogs: (pageNo, limitPerPage, status, include_bookmarks) =>
    `api/v1/blog/list?page=${pageNo}&page_size=${limitPerPage}&status=${status}&include_bookmarks=${include_bookmarks}`,
  GetBlogsDetails: `api/v1/blog/`,
  BookmarkBlog: (id) => `api/v1/blog/${id}/bookmark`,
  BookMarkRemove: (id) => `api/v1/blog/${id}/bookmark`,
  meetingIDAPI: 'api/v1/meeting',
  InviteUser: 'api/v1/join_matrix_chat/',
  InviteConnectionsToFollowCompany: 'api/v1/company/page/follow/invite',
  RecordingAPI: `api/v1/user/recordings`,
  getAggregatedPhotosCount: ({ user, company }) =>
    `api/v1/user/photos/count?user=${user}`,
  getPhotosOfYou: ({ page_size = 10, page = 1, slug }) =>
    `api/v1/user/photos-of-you/list?page_size=${page_size}&page=${page}&user=${slug}`,
  getYourPhotos: ({ page_size = 10, page = 1, user }) =>
    `api/v1/user/your-photos/list?page_size=${page_size}&page=${page}&user=${user}`,
  getCoverPhotos: ({ page_size = 10, page = 1, user }) =>
    `api/v1/user/cover-photos/list?page_size=${page_size}&page=${page}&user=${user}`,
  getProfilePhotos: ({ page_size = 10, page = 1, user }) =>
    `api/v1/user/profile-photos/list?page_size=${page_size}&page=${page}&user=${user}`,
  getYourVideos: ({ page_size = 10, page = 1, user }) =>
    `api/v1/user/your-videos/list?page_size=${page_size}&page=${page}&user=${user}`,
  getVideosOfYour: ({ page_size = 10, page = 1, user }) =>
    `api/v1/user/videos-of-you/list?page_size=${page_size}&page=${page}&user=${user}`,
  getAppointmentsList: ({slug }={}) => `api/v1/appointment/?${!!slug?'&slug='+slug:''}`,
  createAppointments:`api/v1/appointment/`,
  checkAppointmentSlug:({search=''})=>`api/v1/appointment/user/slug?search=${search}`,
  updateAppointments:({ id}) => `api/v1/appointment/${id}`,
  cloneAppointment:({id})=>`api/v1/appointment/${id}/clone`,
  getConnectsionUserList:({ page_size = 10, page = 1, search }) =>
    `api/v1/user/connection?page=${page}&page_size=${page_size}${search.length>0?'&search='+search:''}`,
  inviteConnectionsapp:({ page_size = 10, page = 1, search }) =>`api/v1/appointment/share`,
  bookAppointment:({ appointment_id }) =>`api/v1/appointment/${appointment_id}/book`,
  cancelBooking:({ confirmedSlot }) =>`api/v1/appointment/booking/${confirmedSlot?.id}/cancel`,
  getConnectedCalendarList:()=>`api/v1/calendar/`,
  getConnectedMeetingList:()=>`api/v1/conference/`

};

export const UserAPIEndpoints = {
  GetDegreeCount: (degree) =>
    `api/v1/user/connections/?degree_num=${degree}&limit=0`,
  GetProfileViews: ({ page = 1, size = 10 }) =>
    `api/v1/user/profile/viewed/?page=${page}&page_size=${size}`,
  UpdateUser: 'api/v1/user/settings/update',
  UpdateUserInfo: 'api/v1/user/profile/info/',
  UpdatePassword: 'api/v1/user/change-password',
  ActivateUser: 'api/v1/activate',
  DeactivateUser: 'api/v1/user/deactivate',
  UserByChatId: 'api/v1/user/get_details/',
  UserPresenceStatus: 'api/v1/user/presence',
  DownloadProfilePDF: 'api/v1/user/profile/pdf/',
  AboutMeAISuggest: 'api/v1/user/suggest/aboutme/chatgpt',
  AudiencePermissions: 'api/v1/audience',
  AudienceVisibilityPermissions: 'api/v1/user/audience-visibility',
};

export const CompanyAPIEndpoints = {
  PublicCompanyJobsAPI: 'api/v1/public/jobs',
  CompanyJobsAPI: 'api/v1/jobs',
  JobSearchAPI: 'api/v1/search/jobs',
  JobPreferences: 'api/v1/job',
  CompaniesAPI: 'api/v1/user/company/page/',
  CompaniesTypeAPI: 'api/v1/user/company/page/?type=1',
  JobSubscriptionCheckout: 'api/v1/user/payment/subscription/',
  UserJobSubscriptions: 'api/v1/user/subscription/',
  updateCompanyInfo: (id) => `api/v1/user/company/page/${id}/`,
  AnalyticsApi: (id) => `api/v1/page/analytics/${id}`,
  FollowersApi: (id) =>
    `api/v1/user/company/page/follower-list/?company_id=${id}`,
  FollowCompanyApi: (id) => `api/v1/user/company/page/follow/?company_id=${id}`,
  UnFollowCompanyApi: (id) =>
    `api/v1/user/company/page/unfollow/?company_id=${id}`,
  PublicCompaniesAPI: (slug) => `api/v1/user/public/company/page/${slug}/`,
  CompanyDelete: (id) => `api/v1/user/company/page/update/${id}/deactivate/`,
  CompanyBrochure: `api/v1/user/company/brochure/`,
  CompanyActivity: (id) => `api/v1/company/${id}/activity?page_size=0`,
  SlugCheck: `api/v1/user/company/page/slug/`,
  InviteConnectionsToFollowCompany: 'api/v1/company/page/follow/invite',
  ReportCompanyAPI: 'api/v1/user/company/report/',
  AudiencePermissions: 'api/v1/audience',
  getAggregatedPhotosCount: ({ user, companyId }) =>
    `api/v1/user/photos/count?company=${companyId}`,
  getPhotosOfYou: ({ page_size = 10, page = 1, companyId }) =>
    `api/v1/user/photos-of-you/list?page_size=${page_size}&page=${page}&company=${companyId}`,
  getYourPhotos: ({ page_size = 10, page = 1, companyId }) =>
    `api/v1/user/your-photos/list?page_size=${page_size}&page=${page}&company=${companyId}`,
  getCoverPhotos: ({ page_size = 10, page = 1, companyId }) =>
    `api/v1/user/cover-photos/list?page_size=${page_size}&page=${page}&company=${companyId}`,
  getProfilePhotos: ({ page_size = 10, page = 1, companyId }) =>
    `api/v1/user/profile-photos/list?page_size=${page_size}&page=${page}&company=${companyId}`,
  getYourVideos: ({ page_size = 10, page = 1, companyId }) =>
    `api/v1/user/your-videos/list?page_size=${page_size}&page=${page}&company=${companyId}`,
  getVideosOfYour: ({ page_size = 10, page = 1, companyId }) =>
    `api/v1/user/videos-of-you/list?page_size=${page_size}&page=${page}&company=${companyId}`,
};

export const CommentAPIEndpoints = {
  Comments: (id) => 'api/v1/post/' + id + '/comments',
  dashbordComment: (id) => 'api/v1/post/' + id + '/comments?page_size=1&page=1',
  CreateComment: (id) => 'api/v1/post/' + id + '/comment/create',
  EditComment: (id) => 'api/v1/post/comment/' + id + '/edit',
  CommentLike: (id) => 'api/v1/post/comment/' + id + '/like',
  CommentDisLike: (id) => 'api/v1/post/comment/' + id + '/dislike',
  CommentDelete: (id) => 'api/v1/post/comment/' + id + '/delete',
};

export const GlobalAPIEndpoints = {
  GetFeelingsAndActivities: () =>
    `api/v1/feelings_activities/?category=Feelings,Celebrating,Attending,More`,
  GetCountries: (page, search) =>
    `api/v1/gd/countries/?page=${page}&search=${search}`,
  GetStates: (page, search) =>
    `api/v1/gd/states/?page=${page}&search=${search}`,
  GetCities: ({ page, search, country_code }) =>
    `api/v1/gd/cities/?search=${search}&page=${
      page ?? 1
    }&country_code=${country_code}`,
  GDCompanies: `api/v1/gd/companies/`,
  GetCompanies: (page, search) =>
    `api/v1/gd/companies/?page=${page}&search=${search}`,
  GetCompanyTypes: (page, search) => `api/v1/gd/company_type/`,
  GetCompanySize: (page, search) => `api/v1/gd/company_size/`,
  GetSkills: (page, search) =>
    `api/v1/gd/skills/?page=${page}&search=${search}`,
  GetSchools: (page, search) =>
    `api/v1/gd/schools/?page=${page}&search=${search}`,
  GetColleges: (page, search) =>
    `api/v1/gd/universities/?page=${page}&search=${search}`,
  GetCertificates: (page, search) =>
    `api/v1/gd/certificates/?page=${page}&search=${search}`,
  GetLanguagea: (page, search) =>
    `api/v1/gd/language/?page=${page}&search=${search}`,
  GetCertificateAuthorities: (page, search) =>
    `api/v1/gd/cert_authorities/?page=${page}&search=${search}`,
  GetCourses: (page, search) =>
    `api/v1/gd/courses/?page=${page}&search=${search}`,
  GetDegrees: (page, search) =>
    `api/v1/gd/degrees/?page=${page}&search=${search}`,
  CreateJobTitles: `api/v1/create/job_titles/`,
  GetJobTitles: `api/v1/gd/job_titles/`,
  GetIndustries: ({ page = 1, search, pageSize = 10 }) =>
    `api/v1/gd/industries/?page=${page}${
      search?.length ? `&serach=${search}` : ''
    }&page_size=${pageSize}`,
  GetIndustryDetails: 'api/v1/gd/industries/',
  GetCurrencies: (page, search) => `api/v1/gd/currency_list/`,
  CreateIndustry: () => `api/v1/gd/industries/`,
  GetGlobalLocations: (body) =>
    `api/v1/location/google/search?address=${body?.address}${
      body?.country_code ? `&country_code=${body?.country_code}` : ``
    }${body?.postal_code ? `&postal_code=${body?.postal_code}` : ``}`,
  GetLanguages: (page, search) => `api/v1/gd/language/`,
  GetInterestTraits: (page, search) =>
    `api/v1/gd/interests/?page=${page}&query=${search}`,

  // API's for MeetingCount and ResetMeetingCount
  GetUpcomingMeetingCount: `api/v1/meeting/upcoming/count`,
  UpdateMeetingSeen: `api/v1/meeting/upcoming/viewed`,
};
export const ChatAPiEndpoints = {
  Login: '/login',
  Register: '/register',
  UserPresence: (userId) => `/presence/${userId}/status`,
  CreateRoom: '/createRoom',
  InviteUser: (roomId) => `rooms/${roomId}/invite`,
  JoinRoom: (roomId) => `rooms/${roomId}/join`,
};

export const CampaignAPIEndPoints = {
  Campaign: 'api/v1/ad-campaign/add',
  Objective: 'api/v1/campaign/objective/',
  CapmaignDetails: (campaignId) => `api/v1/ad-campaign/${campaignId}/detail`,
  ExperienceAndAge: 'api/v1/ad-campaign/audience/options',
  Audience: (campaignId) => `api/v1/ad-campaign/${campaignId}/audience`,
  Budget: 'api/v1/campaign/budget/',
  adFormat: (campaignId) => `api/v1/ad-campaign/${campaignId}/ad-format`,
  updatedFormat: (campaignId) => `api/v1/ad-campaign/ad-format/${campaignId}`,
  UploadCampaignMedia: 'api/v1/ad-campaign/media/upload',
  LaunchCampaign: (campaignId) => `api/v1/ad-campaign/${campaignId}/launch`,
  GetAdFormats: (adformat) =>
    `api/v1/ad-campaign/ad-format/list?ad_format_type=${adformat}`,
  adAdPerformance: (campaignId) =>
    `api/v1/ad-campaign/${campaignId}/performance`,
  getSpotlightAds: (location) =>
    `api/v1/ad-campaign/spotlights?${new URLSearchParams({
      country: location?.country ?? '',
      state: location?.state ?? '',
      city: location?.city ?? '',
    }).toString()}`,
};

/* eslint-disable @nx/enforce-module-boundaries */
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GoogleIcon } from '@mybridge/icons/Google';
import { Button, useToast } from '@mybridge/ui';
import { signIn, useSession, signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { googleSignIn } from 'v4/store/actions/auth.actions';

export default function GoogleLoginButton({ path = 'singup', ...props }) {
  const [clientID, setClientID] = useState();

  useEffect(() => {
    try {
      setClientID(process.env.GOOGLE_APP_CLIENT_ID);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return <GButton {...props} />;
}

const GButton = ({ path = 'singup', ...props }) => {
  const { loggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { push } = useRouter();

  const [user, setUser] = useState({});
  const { access } = useSelector((state) => state.user);
  const toast = useToast({
    position: 'top',
    isClosable: true,
  });

  const { data: session } = useSession();

  console.log('session',session,loggedIn);

  useEffect(() => {
    if (session && !loggedIn && session.provider === 'google') {
      const singInObj = {
        id_token: session.id_token,
        email: session.user.email,
        first_name: session?.user?.name?.split(' ')?.[0],
        last_name: session?.user?.name?.split(' ')?.[1],
        access_token: session?.access_token,
        platform: 'google',
      };
      dispatch(googleSignIn(singInObj)).then((res) => {
        if(res){
          console.log(res);
          if(session) signOut();
        }
      });
    }
  }, [session]);

  const handleLogin = () => {
    try {
      console.log('logging in via Google...');
      // login?.();
      signIn('google', { callbackUrl: process?.env?.NEXTAUTH_URL });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button
      p={0}
      leftIcon={<GoogleIcon />}
      onClick={() => handleLogin()}
      h="48px"
      variant="outline"
      borderColor="gray.200"
      id={path}
      className="googleButton"
      {...props}
    >
      Sign in with Google
    </Button>
  );
};

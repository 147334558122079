import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints, UserAPIEndpoints } from '../common/endPoint';

const initialState = {
  msg: '',
  loading: false,
  error: false,
  isEmailUpdated: false,
  emailInfo: {},
  receivedOtpCode: '',
  isResetLink: false,
  isPasswordUpdated: false,
  isMobileOtp: false,
  isDeActivated: false,
  emailSettings: {},
  emailNotifUpdate: false,
  smsSettings: {},
  smsNotifUpdate: false,
  NotificationUpdated: false,
  googleIntegrationUrl: '',
  outlookIntegrationUrl: '',
  calendarList:[],
  confrenceList:[],
  zoomIntegrationUrl:''
};

export const emailList = createAsyncThunk('emailList', async (body) => {
  const obj = {
    url: `${APIEndPoints.Email}`,
    method: 'GET',
  };
  return await API(obj);
});

export const addEmail = createAsyncThunk('addEmail', async (body) => {
  const obj = {
    url: `${APIEndPoints.Email}`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const setPrimaryEmail = createAsyncThunk('setPrimaryEmail', async (body) => {
  const obj = {
    url: `${APIEndPoints.PrimaryEmail}`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const deactivateUser = createAsyncThunk('deactivateUser', async (body) => {
  const obj = {
    url: `${UserAPIEndpoints.DeactivateUser}`,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const getVerifyEmailOTP = createAsyncThunk('getVerifyEmailOTP', async (body) => {
  const obj = {
    url: `${APIEndPoints.VerifyEmail}`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const verifyEmailOTP = createAsyncThunk('verifyEmailOTP', async (body) => {
  const obj = {
    url: `${APIEndPoints.VerifyEmail}`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const removeEmail = createAsyncThunk('removeEmail', async (body) => {
  const obj = {
    url: `${APIEndPoints.Email}`,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateUserPassword = createAsyncThunk('updateUserPassword', async (body) => {
  const obj = {
    url: `${UserAPIEndpoints.UpdatePassword}`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const sendMobileVerifyCode = createAsyncThunk('sendMobileVerifyCode', async () => {
  const obj = {
    url: `${APIEndPoints.mobileVerifyCode}`,
    method: 'POST',
  };
  return await API(obj);
});

export const verifyMobileOtp = createAsyncThunk('verifyMobileOtp', async (body) => {
  const obj = {
    url: `${APIEndPoints.mobileVerifyCode}`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const emailNotifications = createAsyncThunk('emailNotifications', async (body) => {
  const obj = {
    url: `${APIEndPoints.Notification}/email/`,
    method: 'GET',
  };
  return await API(obj);
});

export const getGoogleIntegrationUrl = createAsyncThunk('getGoogleIntegrationUrl', async (body) => {
  const obj = {
    url: `${APIEndPoints.GOOGLE_INTEGRATION}`,
    method: 'GET'
  }
  return await API(obj)
})

export const getGoogleIntegrationCallback = createAsyncThunk('getGoogleIntegrationCallback', async (body) => {
  const obj = {
    url: `${APIEndPoints.GOOGLE_INTEGRATION_CALLBACK(body)}`,
    method: 'GET'
  }
  return await API(obj)
})

// outlook

export const getOutlookIntegrationCallback = createAsyncThunk('getOutlookIntegrationCallback', async (body) => {
  const obj = {
    url: `${APIEndPoints.OUTLOOK_INTEGRATION_CALLBACK(body)}`,
    method: 'GET'
  }
  return await API(obj)
})
// zoom meet
export const getZoomIntegrationUrl = createAsyncThunk('getZoomIntegrationUrl', async (body) => {
  const obj = {
    url: `${APIEndPoints.ZOOM_INTEGRATION}`,
    method: 'GET'
  }
  return await API(obj)
})

export const getZoomIntegrationCallback = createAsyncThunk('getZoomIntegrationCallback', async (body) => {
  const obj = {
    url: `${APIEndPoints.ZOOM_INTEGRATION_CALLBACK(body)}`,
    method: 'GET',
    isNoToken: true

  }
  return await API(obj)
})

export const getMsTeamsIntegrationCallback = createAsyncThunk('getMsTeamsIntegrationCallback', async (body) => {
  const obj = {
    url: `${APIEndPoints.MS_TEAMS_INTEGRATION_CALLBACK(body)}`,
    method: 'GET',
    isNoToken: true
  }
  return await API(obj)
})

export const getWebexIntegrationCallback = createAsyncThunk('getWebexIntegrationCallback', async (body) => {
  const obj = {
    url: `${APIEndPoints.WEBEX_INTEGRATION_CALLBACK(body)}`,
    method: 'GET',
    isNoToken: true
  }
  return await API(obj)
})

export const getGotoIntegrationCallback = createAsyncThunk('getGotoIntegrationCallback', async (body) => {
  const obj = {
    url: `${APIEndPoints.GOTO_INTEGRATION_CALLBACK(body)}`,
    method: 'GET',
    isNoToken: true
  }
  return await API(obj)
})



export const getConnectedCalendarList = createAsyncThunk('getConnectedCalendarList', async (body) => {
  const obj = {
    url: `${APIEndPoints.getConnectedCalendarList()}`,
    method: 'GET'
  }
  return await API(obj)
})

export const getConnectedMeetingList = createAsyncThunk('getConnectedMeetingList', async (body) => {
  const obj = {
    url: `${APIEndPoints.getConnectedMeetingList()}`,
    method: 'GET'
  }
  return await API(obj)
})

export const getOutlookIntegrationUrl = createAsyncThunk('getOutlookIntegrationUrl', async (body) => {
  const obj = {
    url: `${APIEndPoints.OUTLOOK_INTEGRATION}`,
    method: 'GET'
  }
  return await API(obj)
})

export const smsNotifications = createAsyncThunk('smsNotifications', async (body) => {
  const obj = {
    url: `${APIEndPoints.Notification}/sms/`,
    method: 'GET',
  };
  return await API(obj);
});

export const updateNotifications = createAsyncThunk('updateNotifications', async (body) => {
  const obj = {
    url: `${APIEndPoints.Notification}/${body.type}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const clearVerificationState = createAction('clearVerificationState');

const settingsSlice = createSlice({
  name: 'emailSettings',
  initialState,
  reducers: {},
  extraReducers: {
    [emailList.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [emailList.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.emailInfo = action.payload;
      }
    },
    [emailList.rejected]: (state, action) => {
      state.loading = false;
    },
    // videoConfrenceList
    [getConnectedMeetingList.pending]: (state, action) => {
      state.pending = true
      state.error = false;
    },
    [getConnectedMeetingList.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error
      } else {
        state.confrenceList = action.payload;
      }
    },
    [getConnectedMeetingList.rejected]: (state, action) => {
      state.loading = false
    },
    // calendarList
    [getConnectedCalendarList.pending]: (state, action) => {
      state.pending = true
      state.error = false;
    },
    [getConnectedCalendarList.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error
      } else {
        state.calendarList = action.payload;
      }
    },
    [getConnectedCalendarList.rejected]: (state, action) => {
      state.loading = false
    },
    // google meet
    [getGoogleIntegrationUrl.pending]: (state, action) => {
      state.pending = true
      state.error = false;
    },
    [getGoogleIntegrationUrl.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error
      } else {
        state.googleIntegrationUrl = action.payload.url;
      }
    },
    [getGoogleIntegrationUrl.rejected]: (state, action) => {
      state.loading = false
    },
    // zoom meet
    [getZoomIntegrationUrl.pending]: (state, action) => {
      state.pending = true
      state.error = false;
    },
    [getZoomIntegrationUrl.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error
      } else {
        state.zoomIntegrationUrl = action.payload.url;
      }
    },
    [getZoomIntegrationUrl.rejected]: (state, action) => {
      state.loading = false
    },

    [getOutlookIntegrationUrl.pending]: (state, action) => {
      state.pending = true
      state.error = false;
    },
    [getOutlookIntegrationUrl.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error
      } else {
        state.outlookIntegrationUrl = action.payload.url;
      }
    },
    [getOutlookIntegrationUrl.rejected]: (state, action) => {
      state.loading = false
    },

    [addEmail.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isEmailUpdated = false;
    },
    [addEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isEmailUpdated = true;
      }
    },
    [addEmail.rejected]: (state, action) => {
      state.loading = false;
    },

    [setPrimaryEmail.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isEmailUpdated = false;
    },
    [setPrimaryEmail.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isEmailUpdated = true;
      }
    },
    [setPrimaryEmail.rejected]: (state, action) => {
      state.loading = false;
    },

    /* Deactivate User */
    [deactivateUser.pending]: (state, action) => {
      state.deactivationStatus = 'pending';
      state.error = false;
    },
    [deactivateUser.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.deactivationStatus = 'error';
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.deactivationStatus = 'success';
      }
    },
    [deactivateUser.rejected]: (state, action) => {
      state.deactivationStatus = 'error';
      state.error = action.payload;
    },

    /* get otp */

    [getVerifyEmailOTP.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
    },
    [getVerifyEmailOTP.fulfilled]: (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.receivedOtpCode = action.payload;
      }
    },
    [getVerifyEmailOTP.rejected]: (state, action) => {
      state.loading = false;
    },

    /* verify otp */

    [verifyEmailOTP.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.isEmailUpdated = false;
      state.error = false;
    },
    [verifyEmailOTP.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isEmailUpdated = true;
      }
    },
    [verifyEmailOTP.rejected]: (state, action) => {
      state.loading = false;
    },

    /* removeEmail */
    [removeEmail.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
      state.isEmailUpdated = false;
    },
    [removeEmail.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isEmailUpdated = true;
      }
    },
    [removeEmail.rejected]: (state, action) => {
      state.loading = false;
    },

    /* Update User Password */
    [updateUserPassword.pending]: (state, action) => {
      state.updateStatus = 'pending';
      state.error = false;
    },
    [updateUserPassword.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.updateStatus = 'error';
        state.error = action.payload.error;
      } else {
        state.updateStatus = 'success';
        state.error = false;
      }
    },
    [updateUserPassword.rejected]: (state, action) => {
      state.updateStatus = 'error';
      state.error = false;
    },

    // sendMobileVerifyCode

    [sendMobileVerifyCode.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
      state.msg = '';
    },
    [sendMobileVerifyCode.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
        state.msg = 'something went wrong';
      } else {
        state.msg = '';
      }
    },
    [sendMobileVerifyCode.rejected]: (state, action) => {
      state.loading = false;
      state.msg = 'something went wrong';
    },
    /* verifyMobileOtp */
    [verifyMobileOtp.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.msg = '';
      state.isMobileOtp = false;
    },
    [verifyMobileOtp.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
        state.msg = 'something went wrong';
      } else {
        state.msg = 'Phone updated successfully';
        state.isMobileOtp = true;
        state.isProfileUpdated = true;
      }
    },
    [verifyMobileOtp.rejected]: (state, action) => {
      state.loading = false;
      state.msg = 'something went wrong';
    },
    // Email Notif Settings
    [emailNotifications.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [emailNotifications.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.emailSettings = action.payload.results;
        state.emailNotifUpdate = true;
      }
    },
    [emailNotifications.rejected]: (state, action) => {
      state.loading = false;
    },

    // Update Email Notif Settings
    [updateNotifications.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [updateNotifications.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.emailSettings = action.payload.results;
        state.NotificationUpdated = true;
      }
    },
    [updateNotifications.rejected]: (state, action) => {
      state.loading = false;
    },

    // SMS Notif Settings

    [smsNotifications.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [smsNotifications.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.smsSettings = action.payload.results;
        state.smsNotifUpdate = true;
      }
    },
    [smsNotifications.rejected]: (state, action) => {
      state.loading = false;
    },
    [clearVerificationState]: (state, action) => {
      state.receivedOtpCode = '';
    }
  },
});

export default settingsSlice.reducer;
